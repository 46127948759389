<template>
  <div class="pointchange">
    <div class="title">积分获取记录</div>
    <div class="jfitem" v-for="item in DataList">

      <div class="jfnum flexcenter">{{item.type=='zx_ticheng'?'¥':''}}{{item.score}}</div>
      <div class="jfdesc">
        <div>{{item.reason}}</div>
        <div class="jddate">{{item.cdate}}</div>
      </div>
    </div>
    <div v-if="DataList.length==0" style="text-align: center; padding-top: 100px;">
      您还没有积分
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        DataList: []


      }
    },
    beforeMount() {
      this.GetList();
    },

    methods: {

      GetList() {
        this.$store.dispatch("GET", {
          url: "/api/wx/?action=userscore"
        }).then(result => {
          this.DataList = result.data.data.rows

        })
      }
    }
  }
</script>

<style scoped="scoped">
  .pointchange {
    height: 100%;
    width: 100%;
  }

  .title {
    padding-top: 10px;
    font-size: 22px;
    color: #676767;
    text-align: center;
  }

  .jfitem {
    border-bottom: 1px solid #DDDDDD;
    position: relative;
  }

  .jfdesc {
    padding: 8px 4%;
    width: 72%;
  }

  .jfnum {
    width: 20%;
    height: 100%;
    position: absolute;
    right: 0
  }

  .jddate {
    padding: 2px 0;
    font-size: 10px;
  }
</style>
